@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  src: url("./assets/fonts/TitilliumWeb-Regular.ttf") format("ttf");
}